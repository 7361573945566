<template>
  <div class="body_overflow">
    <el-table :data="formatData" :row-style="showRow" v-bind="$attrs" >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" v-if="columns.length===0" min-width="150">
        <template #default="scope">
          <span><i class="iconfont icon-bianji"></i></span>
          <span v-if="iconShow(0,scope.row)" class="tree-ctrl" @click="toggleExpanded(scope.$index)">
            <i v-if="!scope.row._expanded" class="iconfont icon-jiahao"/>
            <i v-else class="iconfont icon-jianhao"/>
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" v-for="(column, index) in columns" v-else :key="column.value" :label="column.text" :width="column.width"  min-width="100">
        <template #default="scope">
          <span v-if="iconShow(index,scope.row)" class="tree-ctrl" @click="toggleExpanded(scope.$index)">
            <i v-if="!scope.row._expanded" class="iconfont icon-jiahao"/>
            <i v-else class="iconfont icon-jianhao"/>
          </span>
          <span v-html="scope.row[column.value]"></span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="330" class-name="small-padding fixed-width" v-if="this.isAdmin == 1">
        <template #default="scope">
          <el-button type="primary" size="small" @click="handleCreate(scope.row)" plain v-if="scope.row.level == 2">添加班级</el-button>
          <el-button type="warning" size="small" @click="handleCheckStudent(scope.row)" plain v-if="scope.row.level != 2">班级详情</el-button>
          <el-button type="success" size="small" @click="handleUpdate(scope.row)" plain>编辑</el-button>
          <el-button size="small" type="danger" @click="del(scope.row,'deleted')" plain>删除</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="330" class-name="small-padding fixed-width" v-else>
        <template #default="scope">
          <el-button type="primary" size="small" @click="handleCreate(scope.row)" plain v-if="scope.row.level == 2">添加班级</el-button>
          <el-button type="warning" size="small" @click="handleCheckStudent(scope.row)" plain v-if="scope.row.level != 2">班级详情</el-button>
          <el-button v-if="scope.row.level != 2" type="success" size="small" @click="handleUpdate(scope.row)" plain>编辑</el-button>
          <el-button v-if="scope.row.level != 2" size="small" type="danger" @click="del(scope.row,'deleted')" plain>删除</el-button>
        </template>
      </el-table-column>
      <slot/>
    </el-table>

    <!-- 编辑新增 -->
    <el-dialog width="30%" :close-on-click-modal="false" destroy-on-close :title="textMap[dialogStatus]" v-model="dialogFormVisible"  class="button_bg">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="100px">
        <div class="common_dialog_box clearfix">
          <div v-if="dialogStatus == 'create'" class="item_one clearfix">
            <el-form-item label="班级名称" class="item">
              <el-input v-model="temp.name" placeholder="请输入班级名称" style="width:80%;margin-left:5px" size="small" ></el-input>
            </el-form-item>
            
            <el-form-item label="创建人" class="item">
              <el-select v-model="temp.teacherId" placeholder="请选择老师" size="small" style="width:80%" clearable filterable>
                <el-option v-for="item in teacherLlist" :key="item.id" :label="item.cnName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <!-- <el-form-item label="班级老师" class="item">
              <el-select v-model="temp.teachingId" placeholder="请选择老师" multiple collapse-tags size="small" style="width:80%" clearable filterable>
                <el-option v-for="item in teacherLlist" :key="item.id" :label="item.cnName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item> -->
          </div>
          <div v-else class="item_one clearfix">
            <el-form-item label="修改班级" class="item" >
              <el-input v-model="temp.name" placeholder="请输入班级名称" style="width:80%;margin-left:5px" size="small" ></el-input>
            </el-form-item>
            <el-form-item label="创建人" class="item" v-if="temp.level != 2">
              <el-select v-model="temp.teacherId" placeholder="请选择老师" size="small" style="width:80%" clearable filterable>
                <el-option v-for="item in teacherLlist" :key="item.id" :label="item.cnName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="班级老师" class="item" v-if="temp.level != 2">
              <el-select v-model="temp.teachingId" placeholder="请选择老师" multiple collapse-tags size="small" style="width:80%" clearable filterable>
                <el-option v-for="item in teacherLlist" :key="item.id" :label="item.cnName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            
          </div>
          <div class="item_one clearfix">
            <el-form-item label="备注" class="item">
              <el-input v-model="temp.remark"  style="width:80%;margin-left:5px" size="small" ></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
          <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()" size="small">确定</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 班级详情 -->
    <el-dialog width="60%" :title="dialogTableTitle" v-model="dialogTableVisible">
      <div style="margin-bottom:10px">
        <el-button type="primary" size="small" @click="checkErweima"><i class="iconfont icon-erweima" style="margin-right:5px"></i> 查看入班二维码</el-button>
        <el-button type="warning" size="small" @click="checkPendingReview">入班审核</el-button>
        <el-button type="danger" size="small" @click="batchDelete" style="float:right">批量删除</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" border @selection-change="handleSelectionChange" :height="contentStyleObj" v-loading="tableLoading">
        <el-table-column type="selection" width="40" />
        <el-table-column prop="name" label="姓名" min-width="120" />
        <el-table-column prop="phone" label="手机号" min-width="120" />
        <el-table-column prop="auditState" label="审核状态" min-width="130">
          <template #default="scope">
            <span style="color:#e6a23c" v-if="scope.row.auditState == 1"> <i class="iconfont icon-gantanhao"></i> 未审核</span> 
            <span style="color:#67c23a" v-else-if="scope.row.auditState == 2"> <i class="iconfont icon-duihao"></i> 审核通过</span> 
            <span style="color:red" v-else-if="scope.row.auditState == 3"> <i class="iconfont icon-cuowu"></i> 审核未通过</span> 
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="140" >
          <template #default="scope">
            <el-button type="danger" @click="deleteStudent(scope.row)" size="small" plain>删除</el-button>
            <el-button type="success" @click="updateStudent(scope.row)" size="small" plain>编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogTableVisible = false" size="small">关闭</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 修改学生信息 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="编辑" v-model="updateStudentDiolog"  class="button_bg">
      <el-form ref="dataForm" :rules="rules" :model="updateStudentForm" label-position="right" label-width="120px">
        <div class="common_dialog_box clearfix">
          <div class="item_one clearfix">
            <el-form-item label="班级" prop="dept" >
              <el-cascader
                style="margin-left:5px;width:150px"
                size="small"
                :props="props"
                v-model="updateStudentValue"
                :options="this.data"
                @change="handleChange"
                clearable></el-cascader>
            </el-form-item>

            <el-form-item label="姓名" prop="name">
              <el-input v-model="updateStudentForm.name" placeholder="请输入内容" style="width:150px;margin-left: 5px;" size="small" ></el-input>
            </el-form-item>
            
            <el-form-item label="修改账号" prop="cnName">
              <el-radio-group v-model="updateStudentForm.type">
                <el-radio :label="1">不修改</el-radio>
                <el-radio :label="2">修改</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="手机号" prop="userName">
              <el-input :disabled="updateStudentForm.type == 1" v-model="updateStudentForm.userName" placeholder="请输入手机号码" style="width:150px;margin-left: 5px;" size="small" ></el-input>
            </el-form-item>
            <el-form-item v-if="updateStudentForm.type == 2" label="验证码" prop="yzm">
              <el-input v-model="updateStudentForm.yzm" placeholder="请输入验证码" style="width:164px;margin-left: 5px;" size="small" ></el-input>
              <el-button size="small" v-if="count" style="width:82px;">{{count}}</el-button>
              <el-button size="small" v-else @click="getYzm" style="width:82px;">{{text}}</el-button>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="updateStudentDiolog = false" size="small">取消</el-button>
          <el-button type="primary" @click="makeSureStudent" size="small">确定</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 待审核学生列表 -->
    <el-dialog width="50%" title="待审核学生列表" v-model="dialogReviewTableVisible">
      <div style="margin-bottom:10px">
        <el-button type="success" size="small" @click="batchReview" style="float:right;margin-bottom: 10px;">批量通过</el-button>
        <el-button type="danger" size="small" @click="batchNotReview" style="float:right;margin-bottom: 10px;margin-right:10px">批量驳回</el-button>
      </div>
      <el-table :data="reviewTableData" style="width: 100%" border @selection-change="handleReviewSelectionChange" :height="contentStyleObj" v-loading="reviewLoading">
        <el-table-column type="selection" width="40" />
        <el-table-column prop="name" label="姓名" min-width="120" />
        <el-table-column prop="phone" label="手机号" min-width="120" />
        <el-table-column prop="auditState" label="审核状态" min-width="130" >
          <template #default="scope">
            <span style="color:#e6a23c" v-if="scope.row.auditState == 1"> <i class="iconfont icon-gantanhao"></i> 未审核</span> 
            <span style="color:#67c23a" v-else-if="scope.row.auditState == 2"> <i class="iconfont icon-duihao"></i> 审核通过</span> 
            <span style="color:red" v-else-if="scope.row.auditState == 3"> <i class="iconfont icon-cuowu"></i> 审核未通过</span> 
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="140" >
          <template #default="scope">
            <el-button type="success" @click="reviewStudent(scope.row)" size="small" plain>通过</el-button>
            <el-button type="danger" @click="notReviewStudent(scope.row)" size="small" plain>驳回</el-button>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogReviewTableVisible = false" size="small">关闭</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 查看班级二维码 -->
    <el-dialog width="20%" title="二维码" v-model="showViewer">
      <el-image
        style="width: 100%; height: 100%"
        :src="url"
        :zoom-rate="1.2"
        :preview-src-list="srcList"
        :initial-index="4"
        fit="cover"
      />
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showViewer = false" size="small">关闭</el-button>
        </div>
      </template>
    </el-dialog>
    

  </div>
</template>
<script>
import treeToArray from './eval'
import { getBaseDepts, saveBaseDept, deleteBaseDept,getSysRoles ,GetTeachers ,getStudentByClass ,DeleteStudentFromClass,GetClassQRCode ,changeAuditStatus} from '@/api/sys.js'
import { isFlowDeclaration } from '@babel/types'
export default {
  name: 'TreeTable',
  props: {
    /* eslint-disable */
    data: {
      type: [Array, Object],
      required: true
    },
    columns: {
      type: Array,
      default: () => []
    },
    evalFunc: Function,
    evalArgs: Array,
    expandAll: {
      type: Boolean,
      default: false
    },
  },
   data() {
    return {
      props: {
        label: 'label',
        value: 'id',
      },
      dialogFormVisible: false,
      dialogTableVisible:false,
      dialogStatus: '',
      textMap: {
        update: '修改',
        create: '新增'
      },
      teacherLlist:[],
      temp: {},
      tempDefaultRoleId: 0,
      rules: [],
      isAdmin : this.$store.getters['user/user'].isAdmin,
      dialogTableTitle:'',
      tableData:[],
      batchDeleteList:[],
      checkDeptId:undefined,
      updateStudentForm: {
        type: 1,
        yzm: '',
        cnName: '',
        userName: '',
        dep: '',
        dept:'',
        deptId:0,
        mobile: '',
        password: '',
        // isLeader: false,
      },
      updateStudentDiolog:false,
      dialogReviewTableVisible:false,
      reviewTableData:[],
      contentStyleObj:{},
      reviewLoading:false,
      tableLoading:false,
      batchReviewSelection:[],
      showViewer:false,
      srcList:[],
      ulr:''


    }
  },
  computed: {
    // 格式化数据源
    formatData: function() {
      let tmp
      if (!Array.isArray(this.data)) {
        tmp = [this.data]
      } else {
        tmp = this.data
      }
      const func = this.evalFunc || treeToArray
      const args = this.evalArgs ? Array.concat([tmp, this.expandAll], this.evalArgs) : [tmp, this.expandAll]
      return func.apply(null, args)
      
    }
  },
  created(){
    this.contentStyleObj= this.$getHeight(620)
    this.getList2()
  },
  methods: {
    auditStatusFilter(type){
      console.log(type);
      if(type == 1){
        return '待审核'
      }else if(type == 2){
        return '审核通过'
      }else if(type == 3){
        return '审核未通过'
      }
    },
    del(e) {
      this.$confirm('确认删除该年级/班级？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteBaseDept({id:e.id*1}).then(res=>{
          this.$emit('getlist')
          this.$store.dispatch("delCachedViewByName", "user-user"); //清除"人员管理"页面缓存
          if(res.data.msg != "success"){
            this.$message.error(res.data.msg);
          }else{
             this.$message({
              type: 'success',
              message: '删除成功'
            });
          }
        })
      })
    },
    getList2() {
      this.listLoading = true
      GetTeachers({}).then(res => {
        this.teacherLlist = res.data.data.list
      })
    },
    adddep(e) {
      this.dialogFormVisible = true
    },

    resetTemp() {
      this.temp = {
        name: '',
        parentId: '',
      }
    },
    handleCreate(row) {
      this.resetTemp()
      this.temp.parentId = row.id
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.temp.id = this.temp.id * 1
          saveBaseDept(this.temp).then((res) => {
            this.$store.dispatch("delCachedViewByName", "user-user"); //清除"人员管理"页面缓存
            if(res.data.msg != "success"){
              return
            }
            // this.list.push(this.temp)
            this.dialogFormVisible = false
              this.$message({
                type: 'success',
                message: '创建成功'
              });
            this.$emit('getlist')
          })
        }
      })
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.temp.name = this.temp.label
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          delete tempData.children;
          delete tempData.parent
          tempData.level = tempData._level
          tempData.teacherId = Number(tempData.teacherId)
          saveBaseDept(tempData).then((res) => {
            this.$store.dispatch("delCachedViewByName", "user-user"); //清除"人员管理"页面缓存
            if(res.data.msg != "success"){
              return
            }
            this.dialogFormVisible = false
             this.$message({
              type: 'success',
              message: '修改成功'
            });
            this.$emit('getlist')
          })
        }
      })
    },
    showRow: function(row) {
      const show = (row.row.parent ? (row.row.parent._expanded && row.row.parent._show) : true)
      row.row._show = show
      return show ? 'animation:treeTableShow 1s;-webkit-animation:treeTableShow 1s;' : 'display:none;'
    },
    // 切换下级是否展开
    toggleExpanded: function(trIndex) {
      const record = this.formatData[trIndex]
      record._expanded = !record._expanded
    },
    // 图标显示
    iconShow(index, record) {
      return (index === 0 && record.children && record.children.length > 0)
    },
    // 班级详情
    handleCheckStudent(row){
      this.checkDeptId = row.id
      this.dialogTableTitle = row.label
      this.dialogTableVisible = true
      this.tableLoading = true
      getStudentByClass({deptId:row.id,auditStatus:[2]}).then(res => {
        this.tableLoading = false
        if(res.data.data.list && res.data.data.list.length !=0){
          this.tableData = res.data.data.list
        }else{
          this.tableData = []
        }
      })
    },
    getCheckStudent(row){
      this.tableLoading = true
      this.checkDeptId = row.deptId
      getStudentByClass({deptId:row.deptId,auditStatus:[2]}).then(res => {
        this.tableLoading = false
        if(res.data.data.list && res.data.data.list.length !=0){
          this.tableData = res.data.data.list
          this.dialogTableVisible = true
        }else{
          this.tableData = []
          this.dialogTableVisible = false
        }
      })
    },
    // 删除学生
    deleteStudent(row){
      this.$confirm("确定删除"+row.name+"吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        DeleteStudentFromClass({student_id:[row.id],deptId:row.deptId}).then(res => {
          if(res.data.msg == "success"){
            this.getCheckStudent(row)
          }
        })
      })
    },
    // 批量删除
    batchDelete(){
      if(this.batchDeleteList.length == 0){
        this.$qzfMessage("请选择学生",1);
        return
      }
      this.$confirm("确定批量删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        DeleteStudentFromClass(this.batchDeleteList).then(res => {
          if(res.data.msg == "success"){
            this.getCheckStudent(this.batchDeleteList[0])
          }
        })
      })
    },
    handleSelectionChange(val){
      let deptId = val[0].deptId
      this.batchDeleteList = []
      let student_id = []
      val.map(v => {
        student_id.push(v.id)
      })
      this.batchDeleteList.push({
        deptId:deptId,
        student_id:student_id
      })
    },
    checkErweima(){
      GetClassQRCode({id:this.checkDeptId}).then(res => {
        this.url = res.data.data.ClassQRCode
        this.srcList = [res.data.data.ClassQRCode]
        this.showViewer = true
      })
    },
    updateStudent(row){
      this.updateStudentForm = row
      this.updateStudentForm.type = 1
      this.updateStudentValue = this.changeDetSelect(row.deptId,this.data)
      this.updateStudentDiolog = true
    },
    // 班级回显
    changeDetSelect(key, treeData) {
      console.log(key,treeData);
      let arr = []; // 在递归时操作的数组
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id;
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (childrenData[j].children) {
              depth++;
              childrenEach(childrenData[j].children, depth);
            }
          }
        }
        return returnArr;
      }
      return childrenEach(treeData, depth);
    },
    // 确认修改
    makeSureStudent(){
      saveUser(this.updateStudentForm).then(res => {
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.updateStudentDiolog = false
          this.getCheckStudent(this.updateStudentForm)
        }
      })
    },
    // 待审核学生列表
    checkPendingReview(){
      this.reviewLoading = true
      getStudentByClass({deptId:this.checkDeptId,auditStatus:[1,3]}).then(res => {
        this.reviewLoading = false
        if(res.data.data.list && res.data.data.list.length !=0){
          this.reviewTableData = res.data.data.list
          this.dialogReviewTableVisible = true
        }else{
          this.reviewTableData = []
          this.$qzfMessage("当前无待审核学生",1)
        }
      })
    },
    // 审核
    reviewStudent(row){
      let param= {
        studentId:[row.id],
        deptId:row.deptId,
        auditStatus:2
      }
      changeAuditStatus(param).then(res => {
        if(res.data.msg == "success"){
          this.checkPendingReview()
          this.getCheckStudent(row)
          this.$emit('getlist')
        }
      })
    },
    // 驳回
    notReviewStudent(row){
      let param= {
        studentId:[row.id],
        deptId:row.deptId,
        auditStatus:3
      }
      changeAuditStatus(param).then(res => {
        if(res.data.msg == "success"){
          this.checkPendingReview()
        }
      })
    },
    // 批量审核
    batchReview(){
      if(this.batchReviewSelection.length == 0 || this.batchReviewSelection[0].studentId.length == 0){
        this.$qzfMessage("请选择学生",1);
        return
      }
      this.batchReviewSelection[0].auditStatus = 2
      changeAuditStatus(this.batchReviewSelection[0]).then(res => {
        if(res.data.msg == "success"){
          this.checkPendingReview()
          this.getCheckStudent(this.batchReviewSelection[0])
        }
      })
    },
    // 批量驳回
    batchNotReview(){
      if(this.batchReviewSelection.length == 0 || this.batchReviewSelection[0].studentId.length == 0){
        this.$qzfMessage("请选择学生",1);
        return
      }
      this.batchReviewSelection[0].auditStatus = 3
      changeAuditStatus(this.batchReviewSelection[0]).then(res => {
        if(res.data.msg == "success"){
          this.checkPendingReview()
        }
      })
    },
    // 待审核列表批量
    handleReviewSelectionChange(val){
      this.batchReviewSelection = []
      let studentId = []
      val.map(v => {
        studentId.push(v.id)
      })
      this.batchReviewSelection.push({
        deptId:this.checkDeptId,
        studentId:studentId,
        auditStatus:0
      })
    },

    // 关闭大图预览
    closeViewer() {
      this.showViewer = false
    },
  }
}
</script>
<style rel="stylesheet/css">
  @keyframes treeTableShow {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  @-webkit-keyframes treeTableShow {
    from {opacity: 0;}
    to {opacity: 1;}
  }
</style>
<style>
.body_overflow .el-table__body-wrapper{
  overflow: auto !important;
}
</style>
<style lang="scss" rel="stylesheet/scss" scoped>
  $color-blue: #2196F3;
  $space-width: 18px;
  .ms-tree-space {
    position: relative;
    top: 1px;
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    width: $space-width;
    height: 14px;
    &::before {
      content: ""
    }
  }
  .processContainer{
    width: 100%;
    height: 100%;
  }
  table td {
    line-height: 26px;
  }

  .tree-ctrl{
    position: relative;
    cursor: pointer;
    color: $color-blue;
    margin-left: -$space-width;
  }
</style>
