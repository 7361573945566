<template>
  <div class="app-container">
    <div style="margin-bottom:20px" class="grid-content bg-purple">
      <el-button @click="handleCreate()" type="primary" size="small" plain v-if="this.isAdmin == 1">新增年级</el-button>
    </div>

    <tree-table :height="contentStyleObj" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" @getlist=init2 :data="data" :columns="columns" border/>
    <!-- 弹框 -->
    <el-dialog width="30%" :close-on-click-modal="false" destroy-on-close :title="textMap[dialogStatus]" v-model="dialogFormVisible"  class="button_bg">
      <el-form ref="dataForm" :model="temp" label-position="right" label-width="100px">
        <div class="common_dialog_box clearfix">
          <div class="item_one clearfix">
            <el-form-item label="年级名称" class="item">
              <el-input v-model="temp.name" placeholder="请输入年级名称" style="width:100%;" size="small" ></el-input>
            </el-form-item>
          </div>
          <div class="item_one clearfix">
            <el-form-item label="备 注" class="item">
              <el-input v-model="temp.remark"  style="width:100%;" size="small" ></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()" size="small">确定</el-button>
      </div>
      </template>
    </el-dialog>
    <!-- 弹框 -->

  </div>
</template>

<script>
import treeTable from '@/components/TreeTable'
import { getBaseDepts, saveBaseDept, getSysRoles } from '@/api/sys.js'
export default {
  name: 'user-dep',
  components: { treeTable },
  data() {
    return {
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '修改',
        create: '新增'
      },
      roleLlist: [],
      temp: {},
      columns: [
        {
          text: '年级/班级名称',
          value: 'label',
          width: 200
        },
        {
          text: '班级学生人数',
          value: 'studentNum',
          width: 120
        },
        {
          text: '新入班申请',
          value: 'newApply',
          width: 120
        },
        {
          text: '备注',
          value: 'remark'
        },
        {
          text: '班级老师',
          value: 'teachingName',
          width: 150
        },
        {
          text: '创建人',
          value: 'teacherName'
        },
      ],
      isAdmin : this.$store.getters['user/user'].isAdmin,
      data: [],
      contentStyleObj:{}, //高度变化
    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(220)
    // window.addEventListener('resize', this.getHeight);
    this.init()
    // this.initBus()
  },
  methods: {
    // initBus(){
    //   this.$bus.off("personUpdate")
    //   this.$bus.on("personUpdate", (val) => {
    //     this.init()
    //   });
    //   this.$bus.off("addUpdate")
    //   this.$bus.on("addUpdate", (val) => {
    //     this.init()
    //   });
    // },
    init(){
      this.getDepts()
      // this.getRoles()
    },
    getRoles() {
      this.listLoading = true
      getSysRoles({}).then(response => {
        this.roleLlist = response.data.data.list
        this.roleId = response.data.data.list[0].id
      })
    },
    resetTemp() {
      this.temp = {
        name: '',
        parentId: '',
      }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.temp.parentId =  this.id*1
          saveBaseDept(this.temp).then((res) => {
            // this.list.push(this.temp)
            if(res.data.msg != "success"){
              return
            }
            this.dialogFormVisible = false
            this.$message({
              type: 'success',
              message: '创建成功'
            });
            this.getDepts()
            this.$store.dispatch("delCachedViewByName", "user-user"); //清除"人员管理"页面缓存
          })
        }
      })
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.temp.dep = arr
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          this.temp.parentId =  this.id*1
          // this.temp.deptPosition = this.temp.dep.join('-')
          saveBaseDept(tempData).then((res) => {
            this.$store.dispatch("delCachedViewByName", "user-user"); //清除"人员管理"页面缓存
            if(res.data.msg != "success"){
              return
            }
            this.getDepts()
            this.dialogFormVisible = false
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    init2() {
      this.getDepts()
    },
    getDepts() {
      getBaseDepts({}).then(res => {
        let big = res.data.data.list[0]
        this.id = big.id
        this.data = big.children?big.children:[]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.item_one {
  display: flex;
}
</style>